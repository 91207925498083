import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useClientsList() {
  // Use toast
  const toast = useToast()

  const refClientListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'client_name', sortable: true },
    { key: 'parent_client', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalClients = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('client_name')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)
  const statusFilter = ref('active')
  const servicesFilter = ref(null)
  const clientsList = ref(null)
  const clientsListComplete = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refClientListTable.value ? refClientListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    }
  })

  const refetchData = () => {
    refClientListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, servicesFilter], () => {
    refetchData()
  })

  const fetchClients = (ctx, callback) => {
    console.log(ctx)
    if (ability.can('read', 'clients')) {
      const qvars = {
        forceRefresh: store.state.clients.fetchingClientList,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        service: servicesFilter.value,
      }
      console.log(qvars)
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          const { clients } = response.data
          callback(clients)
          clientsListComplete.value = clients
          totalClients.value = response.data.totalClients
          const tmp = []
          clients.forEach(item => {
            tmp.push({ label: item.display_name, value: item.clientname })
          })
          clientsList.value = tmp
          console.log('clientsList')
          console.log(clientsList)
        })
        .catch(error => {
          console.log(error) // No current user
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveClientStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'disabled') return 'secondary'
    return 'success'
  }

  return {
    fetchClients,
    tableColumns,
    perPage,
    currentPage,
    totalClients,
    clientsList,
    clientsListComplete,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refClientListTable,

    resolveClientStatusVariant,
    refetchData,

    // Extra Filters
    statusFilter,
    servicesFilter,
  }
}
